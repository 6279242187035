import "../css/style.css";

import Swiper, { Pagination, EffectCreative, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

/*=============== COOKIES ===============*/

function cookies(functions) {
  const container = document.querySelector(".cookies__container");
  const save = document.querySelector(".cookies__save");
  if (!container || !save) return null;

  const localPref = JSON.parse(window.localStorage.getItem("cookies-pref"));
  if (localPref) activateFunctions(localPref);

  function getFormPref() {
    return [...document.querySelectorAll("[data-function]")]
      .filter((el) => el.checked)
      .map((el) => el.getAttribute("data-function"));
  }

  function activateFunctions(pref) {
    pref.forEach((f) => functions[f]());
    container.style.display = "none";
    window.localStorage.setItem("cookies-pref", JSON.stringify(pref));
  }

  function handleSave() {
    const pref = getFormPref();
    activateFunctions(pref);
  }

  save.addEventListener("click", handleSave);
}

function marketing() {}

function analytics() {}

cookies({
  marketing,
  analytics,
});

/*=============== MODAL ===============*/
function iniciaModal(modalID) {
  if (localStorage.fechaModal !== modalID) {
    const modal = document.getElementById(modalID);
    if (modal) {
      modal.classList.add("mostrar");
      modal.addEventListener("click", (e) => {
        if (e.target.id == modalID || e.target.className == "fechar") {
          modal.classList.remove("mostrar");
          localStorage.fechaModal = modalID;
        }
      });
    }
  }
}

document.addEventListener("scroll", () => {
  if (window.pageYOffset > 800) {
    iniciaModal("modal-promocao");
  }
});

/*=============== SHOW SCROLL UP ===============*/
function scrollUp() {
  const scrollUp = document.getElementById("scroll-up");
  if (this.scrollY >= 400) scrollUp.classList.add("show-scroll");
  else scrollUp.classList.remove("show-scroll");
}
window.addEventListener("scroll", scrollUp);

/*=============== SCROLL REVEAL ANIMATION ===============*/
const sr = ScrollReveal({
  origin: "top",
  distance: "60px",
  duration: 1200,
  delay: 400,
});

sr.reveal(`.animation`);
sr.reveal(`.animation__left`, { origin: "left" });
sr.reveal(`.animation__right`, { origin: "right" });
sr.reveal(`.animation__interval`, { interval: 50 });

/*=============== ===============*/

let menu_icons = document.querySelector(".menu-icons"),
  nav = document.querySelector("nav");

menu_icons.addEventListener("click", () => {
  nav.classList.toggle("active");
});

/*********************************************************************************************/
const swiper = new Swiper(".mySwiper", {
  grabCursor: true,
  loop: true,
  effect: "creative",
  modules: [Pagination, EffectCreative, Autoplay],
  creativeEffect: {
    prev: {
      shadow: true,
      translate: ["-120%", 0, -500],
    },
    next: {
      shadow: true,
      translate: ["120%", 0, -500],
    },
  },
  speed: 1000,
  fadeEffect: {
    crossFade: true,
  },
  autoplay: {
    delay: 3000,
    pauseOnMouseEnter: true,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true,
  },
});

/*=============== VÍDEO BANNER ===============*/
document.addEventListener("DOMContentLoaded", function () {
  var bannerVideo = document.getElementById("bannerVideo");
  var soundToggle = document.getElementById("soundToggle");
  var videoOverlay = document.getElementById("videoOverlay");

  if (bannerVideo && soundToggle && videoOverlay) {
    // Adicione um ouvinte de evento "loadeddata" ao vídeo
    bannerVideo.addEventListener("loadeddata", function () {
      // Exiba o aviso e o vídeo-overlay quando o vídeo terminar de carregar
      soundToggle.style.display = "block";
      videoOverlay.style.display = "block";
    });

    videoOverlay.addEventListener("click", function () {
      if (bannerVideo.muted) {
        bannerVideo.muted = false;
        soundToggle.style.display = "none";
      } else {
        bannerVideo.muted = true;
        soundToggle.style.display = "block";
      }
    });
  }
});
